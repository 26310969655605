import React, { useState, useEffect, useRef } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { Button } from 'primereact/button';

function generateRandom64BitInteger() {
  return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
}


const AdditionalRates = ({ additionalRates, getAdditionalRates }) => {
  const arePropsReadOnce = useRef(false);
  const singleAdditionalRateRow = {
    id: generateRandom64BitInteger(),
    name: '',
    value: 0,
    status: true
  };
  
  const [localAdditionalRates, setLocalAdditionalRates] = useState(undefined);
 
  useEffect(() => {
    if (!arePropsReadOnce.current && additionalRates) {
      arePropsReadOnce.current = true;
      setLocalAdditionalRates(additionalRates)
    }
    
  }, [additionalRates]);

  const addNewAdditionalRateRow = () => {
    setLocalAdditionalRates([
      ...localAdditionalRates,
      singleAdditionalRateRow
    ])
  }

  const removeRow = (index) => {
    const filteredRates = localAdditionalRates?.filter((_, i) => index !== i);
    setLocalAdditionalRates(filteredRates);
  }

  useEffect(() => {
    getAdditionalRates(localAdditionalRates);
  }, [localAdditionalRates])

  const handleChange = (field, value, id) => {
    setLocalAdditionalRates(
      localAdditionalRates?.map((rate, i) => rate.id === id ? { ...rate, [field]: value } : rate));
  }
 
  const isAllRowsDeleted = () => {
    return localAdditionalRates?.every(rate => !rate.status);
  }  

  return (
    <>
      <div className='mb-3' style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
        <div className='mb-3' style={{ fontWeight: 700, fontSize: "larger"}}>
          Additional Charges
        </div>
        {isAllRowsDeleted() && <Button
          label={'Add rate'}
          icon={'pi pi-plus'}
          className={`mr-3 p-button-outlined p-button-success`}
          onClick={addNewAdditionalRateRow} />}
      </div>

      {localAdditionalRates
        ?.filter(rate => rate.status)
        ?.map((rate) =>
          <AdditionalRate
            index={rate.id}
            value={rate.value}
            name={rate.name}
            removeRow={removeRow}
            addNewAdditionalRateRow={addNewAdditionalRateRow}
            handleChange={handleChange}
            isDeleteButtonDisabled={localAdditionalRates.length < 2} />)}
      
      <div className='mb-3' style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", width: "100%", fontWeight: 500 }}>
        <div> Total Additional Charges </div>
        <div className='ml-3'>
          {localAdditionalRates
            ?.filter(rate => rate.status)
            ?.reduce((acc, rate) => rate.value + acc ,0)}
        </div>
      </div>    
    </>);
};

export default AdditionalRates;


const AdditionalRate = ({
  handleChange,
  index,
  addNewAdditionalRateRow,
  name,
  value,
  isDeleteButtonDisabled
}) => {
  const [localName, setLocalName] = useState(name);
  const [localValue, setLocalValue] = useState(value);
  const errorMessage = 'Please fill description';

  useEffect(() => {
    handleChange("value", parseFloat(localValue, 10), index);
  }, [localName]);

  return (
    <Row>  
        <Form.Label column md="3">
          Description
        </Form.Label>
        <Col md="4">
          <Form.Control
            placeholder="Description"
            name="name"
            value={name}
            onChange={(event) => {
              setLocalName(event.target.value);
              handleChange("name", event.target.value, index);
            }}
            isInvalid={!!localValue && !localName}
          />
          {!!localValue && !localName && (
            <Form.Control.Feedback type="invalid">
              {errorMessage}
            </Form.Control.Feedback>
          )}
        </Col>
        <Form.Label column md="1" style={{ paddingRight: 0}}>
          Rate
        </Form.Label>
        <Col md="2">
          <Form.Control
            placeholder="Rate"
            name="value"
            type="number"
            value={value}
            isInvalid={localValue < 0}
            onChange={(event) => {
              setLocalValue(parseFloat(event.target.value, 10));
              if (event.target.value && localName) {
                handleChange("value", parseFloat(event.target.value, 10), index);
              }
            }}
          />
          {localValue < 0 && (
            <Form.Control.Feedback type="invalid">
              {"Rate can not be less than 0"}
            </Form.Control.Feedback>
          )}
        </Col>
        <Col md="1">
            <Button
              tooltip={'Add rate'}
              tooltipOptions={{ position: 'left' }}
              label={''}
              icon={'pi pi-plus'}
              className={`mr-3 p-button-rounded p-button-outlined p-button-success`}
              onClick={addNewAdditionalRateRow}
            />
        </Col>
        <Col md="1">
            <Button
              tooltip={'Delete rate'}
              tooltipOptions={{ position: 'right' }}
              label={''}
              disabled={isDeleteButtonDisabled}
              icon={'pi pi-trash'}
              className={`mr-3 p-button-rounded p-button-outlined p-button-danger`}
              onClick={() => handleChange("status", false, index)}
            />
         
        </Col>
    </Row>
  );
}